import * as React from 'react'
import styled from 'styled-components'
import { InfoAndImage } from 'views/components'
import { breakpoints, laptop, tablet, mobile } from 'shared/media-queries'
import { cyan } from 'shared/colors'
import RespondImage from './img'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.desktop) {
		return '486px'
	} else if (screenWidth > breakpoints.tablet) {
		return '460px'
	}
	return '636px'
}

const Subtitle = styled.div`
	font-size: 38px;
	${laptop} {
		max-width: 460px;
	}
	${tablet} {
		max-width: 642px;
	}
	${mobile} {
		font-size: 32px;
		max-width: 100%;
		margin: 0 auto;
	}
`

const Info = styled.div`
	font-size: 22px;
	${laptop} {
		max-width: 460px;
	}
	${tablet} {
		max-width: 642px;
	}
	${mobile} {
		font-size: 22px;
		max-width: 100%;
		margin: 0 auto;
	}
`

// prettier-ignore
interface Props {
	screenWidth: number;
	subtitle: string;
	info: string;
	country: string;
}

const Respond: React.FC<Props> = ({ country, screenWidth, subtitle, info }) => (
	<InfoAndImage
		screenWidth={screenWidth}
		image={<RespondImage country={country} />}
		spacerWidths={['100px', '50px']}
		stackedImageOnTop
		title="RESPOND TO INVITES"
		subtitle={<Subtitle>{subtitle}</Subtitle>}
		info={<Info>{info}</Info>}
		centered={screenWidth < breakpoints.laptop}
		wordingWidth={getWordingWidth(screenWidth)}
		dotColor={cyan}
	/>
)

export default Respond
