import * as React from 'react'
import Spacer from 'components/spacer'
import { mobile } from 'shared/media-queries'
import Heading from './heading'

import BiddingImage from './img'

// prettier-ignore
interface Props {
	screenWidth: number;
	title: string;
	subtitle: string;
	info: string;
	country: string;
}

const ManageBids: React.FC<Props> = ({
	screenWidth,
	title,
	subtitle,
	info,
	country,
}) => (
	<div>
		<Heading title={title} subtitle={subtitle} info={info} />
		<Spacer
			height="50px"
			css={{
				[mobile]: { height: '30px' },
			}}
		/>
		<BiddingImage screenWidth={screenWidth} country={country} />
		<Spacer
			height="50px"
			css={{
				[mobile]: { height: '30px' },
			}}
		/>
	</div>
)

export default ManageBids
