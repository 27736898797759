import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { breakpoints } from 'shared/media-queries'
import { SWProps } from '../../shared/interfaces'

const BackgroundImage: React.FC<SWProps> = ({ screenWidth }) => {
	const data = useStaticQuery(
		graphql`
			query {
				desktop: file(
					relativePath: { eq: "bid-board/bid-invites/header/img/desktop.png" }
				) {
					childImageSharp {
						fixed(height: 600, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				laptop: file(
					relativePath: { eq: "bid-board/bid-invites/header/img/laptop.png" }
				) {
					childImageSharp {
						fixed(height: 500, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				tablet: file(
					relativePath: { eq: "bid-board/bid-invites/header/img/tablet.png" }
				) {
					childImageSharp {
						fixed(height: 400, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				mobile: file(
					relativePath: { eq: "bid-board/bid-invites/header/img/mobile.png" }
				) {
					childImageSharp {
						fixed(height: 403, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	)

	if (screenWidth <= breakpoints.mobile) {
		return (
			<GatsbyImage
				fixed={data.mobile.childImageSharp.fixed}
				alt="Bid Invites Header Image"
			/>
		)
	} else if (screenWidth <= breakpoints.tablet) {
		return (
			<GatsbyImage
				fixed={data.tablet.childImageSharp.fixed}
				alt="Bid Invites Header Image"
			/>
		)
	} else if (screenWidth <= breakpoints.laptop) {
		return (
			<GatsbyImage
				fixed={data.laptop.childImageSharp.fixed}
				alt="Bid Invites Header Image"
			/>
		)
	}
	return (
		<GatsbyImage
			fixed={data.desktop.childImageSharp.fixed}
			alt="Bid Invites Header Image"
		/>
	)
}

export default BackgroundImage
