import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const BackgroundImage: React.FC = () => {
	const data = useStaticQuery(
		graphql`
			query {
				filename: file(
					relativePath: {
						eq: "bid-board/bid-invites/get-on-network/img/image-uk.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1400) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<GatsbyImage
			fluid={data.filename.childImageSharp.fluid}
			alt="GOTN alt Image"
		/>
	)
}

export default BackgroundImage
