import * as React from 'react'
import styled from 'styled-components'
import { Hero } from 'views/components'
import H1 from 'components/h1'
import { breakpoints, mobile } from 'shared/media-queries'
import BackgroundImage from './img'
import { isUSA } from 'shared/get-country'

const getWordingWidth = (screenWidth, country) => {
	if (screenWidth >= breakpoints.laptop) {
		return isUSA(country) ? '486px' : '540px'
	} else if (screenWidth >= breakpoints.tablet) {
		return '700px'
	}
	return '480px'
}

const Heading = styled(H1)`
	font-size: 54px;
	${mobile} {
		font-size: 32px;
	}
`

const SubHeading = styled.div`
	font-size: 24px;
	${mobile} {
		font-size: 18px;
	}
`

// prettier-ignore
interface Props {
	screenWidth: number;
	heading: string;
	subheading: string;
	ctas: Array<any>;
	country: string;
}

const Header: React.FC<Props> = ({
	screenWidth,
	heading,
	subheading,
	ctas,
	country,
}) => (
	<Hero
		backgroundImage={
			<BackgroundImage screenWidth={screenWidth} country={country} />
		}
		heading={<Heading>{heading}</Heading>}
		subHeading={<SubHeading>{subheading}</SubHeading>}
		wordingWidth={getWordingWidth(screenWidth, country)}
		CTAs={ctas}
	/>
)

export default Header
