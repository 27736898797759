import * as React from 'react'
import Layout from 'components/layout'
import { SectionSpacer, ContentWrapper, GrayBackground } from 'views/components'
import useScreenWidth from 'hooks/use-screen-width'

import Header from './header'
import Respond from './respond'
import ManageBids from './manage'
import GetOnNetwork from './get-on-network'
import Footer from './footer'
import { numberOfGCsOnBC } from 'shared/constants'
import withCountry from 'shared/get-country'
import generateContent from './content'

const BidInvitesLP = ({ country }) => {
	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}

	const content = generateContent(country)
	if (!country) return null
	const { header, network, respond, manage, footer } = content

	return (
		<Layout
			title="Get More Bid Invites | BuildingConnected"
			description={`Get invitations to bid on the projects you want by completing your profile. Over 4M invites are sent by over ${numberOfGCsOnBC} GCs every month.`}
		>
			<Header screenWidth={screenWidth} {...header} country={country} />
			<SectionSpacer />
			<ContentWrapper>
				<GetOnNetwork
					screenWidth={screenWidth}
					{...network}
					country={country}
				/>
				<SectionSpacer />
			</ContentWrapper>
			<GrayBackground>
				<ContentWrapper>
					<SectionSpacer />
					<Respond screenWidth={screenWidth} {...respond} country={country} />
					<SectionSpacer />
				</ContentWrapper>
			</GrayBackground>
			<SectionSpacer />
			<ContentWrapper>
				<ManageBids screenWidth={screenWidth} {...manage} country={country} />
				<SectionSpacer />
			</ContentWrapper>
			<Footer {...footer} />
		</Layout>
	)
}

export default withCountry(BidInvitesLP)
