import * as React from 'react'
import styled from 'styled-components'
import { InfoAndImage } from 'views/components'
import { breakpoints, laptop, tablet, mobile } from 'shared/media-queries'
import { cyan, grayText } from 'shared/colors'
import GetOnNetworkImage from './img'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.desktop) {
		return '499px'
	} else if (screenWidth > breakpoints.tablet) {
		return '380px'
	}
	return '636px'
}

const Subtitle = styled.div`
	font-size: 38px;
	${laptop} {
		max-width: 380px;
	}
	${tablet} {
		max-width: 642px;
	}
	${mobile} {
		font-size: 32px;
		max-width: 100%;
		margin: 0 auto;
	}
`

const Info = styled.div`
	font-size: 22px;
	${laptop} {
		max-width: 350px;
	}
	${tablet} {
		max-width: 550px;
	}
	${mobile} {
		font-size: 21px;
		max-width: 100%;
		margin: 0 auto;
	}
`

const Subtext = styled.div`
	margin-top: 35px;
	width: 100%;
	font-size: 18px;
	color: ${grayText};
	${tablet} {
		max-width: 490px;
		text-align: center;
	}
`
// prettier-ignore
interface Props {
 screenWidth: number;
 info: string;
 subtext: string;
 bulletItems: Array<any>;
 country: string;
}

const GetOnNetwork: React.FC<Props> = ({
	screenWidth,
	info,
	bulletItems,
	subtext,
	country,
}) => (
	<InfoAndImage
		screenWidth={screenWidth}
		image={<GetOnNetworkImage country={country} />}
		spacerWidths={['100px', '90px']}
		title="GET ON THE NETWORK"
		stackedImageOnTop
		imageOnRight
		dotColor={cyan}
		subtitle={
			<Subtitle>
				Complete your profile to get invited to the right projects.
			</Subtitle>
		}
		info={<Info>{info}</Info>}
		bulletItems={bulletItems}
		centered={screenWidth < breakpoints.laptop}
		wordingWidth={getWordingWidth(screenWidth)}
		additionalSubText={<Subtext>{subtext}</Subtext>}
	/>
)

export default GetOnNetwork
