import * as React from 'react'
import styled from 'styled-components'
import { cyan, gray80 } from 'shared/colors'
import Spacer from 'components/spacer'
import H2 from 'components/h2'
import { laptop, tablet, mobile } from 'shared/media-queries'

const Main = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Title = styled.div`
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 1px;
	color: ${cyan};
`

const Subtitle = styled(H2)`
	width: 782px;
	text-align: center;
	${tablet} {
		font-size: 36px;
		width: 635px;
	}
	${mobile} {
		width: 100%;
	}
`

const Info = styled.div`
	font-size: 22px;
	font-weight: 300;
	line-height: 1.33;
	color: ${gray80};
	text-align: center;
	width: 662px;
	${laptop} {
		width: 662px;
	}
	${tablet} {
		width: 512px;
	}
	${mobile} {
		line-height: 1.25;
		width: 100%;
	}
`

// prettier-ignore
interface Props {
	title: string;
	subtitle: string;
	info: string;
}

const Heading: React.FC<Props> = ({ title, subtitle, info }) => (
	<Main>
		<Title>{title}</Title>
		<Spacer height="20px" />
		<Subtitle>{subtitle}</Subtitle>
		<Spacer height="20px" />
		<Info>{info}</Info>
	</Main>
)

export default Heading
