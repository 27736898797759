import * as React from 'react'
import styled from 'styled-components'
import { laptop, tablet, mobile } from 'shared/media-queries'
import { navy } from 'shared/colors'
import Spacer from 'components/spacer'

const Container = styled.div`
	height: 475px;
	background-color: ${navy};
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	${mobile} {
		height: 530px;
	}
`

const TextContainer = styled.div`
	color: white;
	max-width: 1000px;
	font-size: 54px;
	font-weight: 700;
	line-height: 1.1;
	${laptop} {
		max-width: 760px;
	}
	${tablet} {
		max-width: 700px;
	}
	${mobile} {
		max-width: 420px;
		font-size: 32px;
	}
`

// prettier-ignore
interface Props {
	text: string;
	ctas: React.ReactNode;
}

const BidInviteFooter: React.FC<Props> = ({ text, ctas }) => (
	<Container>
		<TextContainer>{text}</TextContainer>
		<Spacer height="42px" />
		{ctas}
	</Container>
)

export default BidInviteFooter
