import * as React from 'react'
import styled from 'styled-components'
import { countryCodes } from 'shared/get-country'
import { numberOfGCsOnBC } from 'shared/constants'
import { Button as OldButton } from 'views/components'
import button from 'components/button'
import { mobile } from 'shared/media-queries'
import { cyan, navy } from 'shared/colors'
import { sitePath } from 'shared/constants'
import { Link } from 'gatsby'
import { showRequestDemoModal } from 'components/modal-manager'

const StyledButton = styled(OldButton)`
	padding: 0px 20px;
	background-color: ${cyan};
	${mobile} {
		margin-top: 10px;
		margin-left: 0px;
	}
`

const BulletItem = styled.span`
	font-weight: 300;
`

const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	${mobile} {
		flex-direction: column;
	}
`

const AccountContainer = styled.div`
	color: white;
	font-size: 18px;
`

const A = styled.a`
	color: cyan;
`

const FlexSpacer = styled.div`
	flex: 0 0 25px;
`

const FooterButton = styled(button)`
	background-color: ${navy};
	border: 1px solid ${cyan};
	padding: 0 20px;
	width: 287px;
	${mobile} {
		margin-top: 20px;
		width: 100%;
	}
`

const DemoButton = styled(button)`
	background-color: ${cyan};
	width: 287px;
	${mobile} {
		width: 100%;
	}
`

const usCopy = {
	header: {
		heading: "Don't miss out on bids. Get on BuildingConnected.",
		subheading: `Right now ${numberOfGCsOnBC}+ general contractors send out 4M+ bid invites per month on the largest construction network. Get discovered today.`,
		ctas: [
			<StyledButton
				label="Complete your Profile"
				key="Complete your Profile"
				onClick={() => {
					window.open(`${sitePath}/company-profile`, '_blank')
				}}
			/>,
		],
	},
	network: {
		info:
			'Identify key information so general contractors know which bids to send your way.',
		bulletItems: [
			<BulletItem>
				<b>Work performed:</b> List the trades you'd like to be invited to
			</BulletItem>,
			<BulletItem>
				<b>Service area:</b> Choose the counties you're willing to work in
			</BulletItem>,
			<BulletItem>
				<b>Contact info:</b> Get invites sent to the right email
			</BulletItem>,
		],
		subtext:
			'This is the only information available to general contractors on the BuildingConnected Network —everything else is private and secure to your account.',
	},
	respond: {
		subtitle:
			'Get better search rankings by bidding through BuildingConnected.',
		info:
			'The more you engage with general contractors on the BuildingConnected Network, the higher you rank, and the easier it is for the right general contractors to find you. Become an active user by accepting or declining invites, and submitting proposals.',
	},
	manage: {
		title: 'MANAGE BIDS',
		subtitle: 'Do more than download files—manage all your bids too.',
		info:
			'Your online bid board lets you manage invites throughout every step of the preconstruction process in one place.',
	},
	footer: {
		ctas: (
			<>
				<ButtonContainer>
					<Link to={'bid-board/move-bid-board-online/'}>
						<FooterButton>See How</FooterButton>
					</Link>
					<FlexSpacer />
					<DemoButton onClick={showRequestDemoModal}>
						Get a demo of Bid Board Pro
					</DemoButton>
				</ButtonContainer>
				<FlexSpacer />
				<AccountContainer>
					<span>Need an account? </span>
					<A href={`${sitePath}/create-account`}>Create one now.</A>
				</AccountContainer>
			</>
		),
		text:
			"Now that you're on the network, move your entire bid board online to track everything in one place.",
	},
}

const anzCopy = {
	header: {
		heading: 'Get discovered on the BuildingConnected network.',
		subheading:
			'Receive invites from Head Contractors and easily manage your tenders.',
		ctas: [
			<StyledButton
				label="Create an account"
				key="Create an account"
				onClick={() => {
					window.open(
						'https://app.buildingconnected.com/create-account',
						'_blank'
					)
				}}
			/>,
		],
	},
	network: {
		info:
			'Identify key information so head contractors know which tenders to send your way.',
		bulletItems: [
			<BulletItem>
				<b>Work performed:</b> List the trades you'd like to be invited to
			</BulletItem>,
			<BulletItem>
				<b>Service area:</b> Choose the areas you're willing to work in
			</BulletItem>,
			<BulletItem>
				<b>Contact info:</b> Get invites sent to the right email
			</BulletItem>,
		],
		subtext:
			'This is the only information available to head contractors on the BuildingConnected network—everything else is private and secure to your account.',
	},
	respond: {
		subtitle:
			'Get better search rankings by tendering through BuildingConnected.',
		info:
			'The more you engage with head contractors on the BuildingConnected network, the higher you rank, and the easier it is for the right head contractors to find you. Become an active user by accepting or declining tenders and submitting quotes.',
	},
	manage: {
		title: 'MANAGE TENDERS',
		subtitle:
			'Do more than download files—manage all your BuildingConnected tenders too.',
		info:
			'Track invites throughout every step of the preconstruction process in one place.',
	},
	footer: {
		ctas: (
			<>
				<ButtonContainer>
					<DemoButton
						onClick={() => {
							window.open(
								'https://app.buildingconnected.com/create-account',
								'_blank'
							)
						}}
					>
						Get Started
					</DemoButton>
					<FlexSpacer />
					<Link to={'bid-board/toolkit/'}>
						<FooterButton>Get the Tendering toolkit</FooterButton>
					</Link>
				</ButtonContainer>
				<FlexSpacer />
				<AccountContainer>
					<span>Need an account? </span>
					<A href={`${sitePath}/create-account`}>Create one now.</A>
				</AccountContainer>
			</>
		),
		text: 'Join the network and start getting invited to tender today.',
	},
}

const ukCopy = {
	...anzCopy,
	header: {
		...anzCopy.header,
		subheading:
			'Receive invites from Main Contractors and easily manage your tenders.',
	},
	network: {
		...anzCopy.network,
		info:
			'Identify key information so main contractors know which tenders to send your way.',
		subtext:
			'This is the only information available to main contractors on the BuildingConnected network—everything else is private and secure to your account.',
	},
	respond: {
		...anzCopy.respond,
		info:
			'The more you engage with main contractors on the BuildingConnected network, the higher you rank, and the easier it is for the right main contractors to find you. Become an active user by accepting or declining tenders and submitting quotes.',
	},
}

const copy = {
	[countryCodes.us]: usCopy,
	[countryCodes.au]: anzCopy,
	[countryCodes.nz]: anzCopy,
	[countryCodes.uk]: ukCopy,
	[countryCodes.ie]: ukCopy,
}

const content = country => copy[country]

export default content
