import * as React from 'react'
import styled from 'styled-components'
import { mobile, tablet } from 'shared/media-queries'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { SWProps } from '../../shared/interfaces'

const ImageContainer = styled.div`
	margin: 0 auto;
	max-width: 1163px;
	${tablet} {
		max-width: 644px;
	}
	${mobile} {
		max-width: 100%;
	}
`

const BiddingImage: React.FC<SWProps> = ({ screenWidth }) => {
	const data = useStaticQuery(
		graphql`
			query {
				fileName: file(
					relativePath: { eq: "bid-board/bid-invites/manage/img/image-ie.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 2326) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mobile: file(
					relativePath: { eq: "bid-board/bid-invites/manage/img/image-ie.png" }
				) {
					childImageSharp {
						fixed(height: 239, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	)

	if (screenWidth <= 425) {
		return (
			<ImageContainer>
				<GatsbyImage
					fixed={data.mobile.childImageSharp.fixed}
					alt="BBP Mobile Image"
				/>
			</ImageContainer>
		)
	}
	return (
		<ImageContainer>
			<GatsbyImage
				fluid={data.fileName.childImageSharp.fluid}
				alt="BBP Image"
			/>
		</ImageContainer>
	)
}

export default BiddingImage
